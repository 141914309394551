<template>
<div class="lso">
   <div class="menuWrap">
        <div class="menu">
          <span>培训类别：</span>
          <ul>
            <li @click="zeroScreening(item, i)" v-for="(item, i) in zero" :key="i" :style="index0 == item.id?{color: $store.state.themeColor,fontWeight: 'bold'}:{}">{{item.name}}</li>
          </ul>
        </div>
        <div class="menu">
          <span>培训科目：</span>
          <ul :class="{'menu-show-style': menuAllShow}" class="first-menus">
            <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :style="index == i?{color: $store.state.themeColor,fontWeight: 'bold'}:{}">{{item.name}}</li>
          </ul>
        </div>
        <div class="menu" v-if="second.length > 0">
          <span>培训内容：</span>
          <ul>
            <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :style="secondId == item.id?{color: $store.state.themeColor,fontWeight: 'bold'}:{}">{{item.name}}</li>
          </ul>
        </div>
        <div class="menu" v-if="third.length > 0">
          <span>内容分类：</span>
          <ul>
            <li @click="thirdScreening(item, i)" v-for="(item, i) in third" :key="i" :style="third_cate_id == item.id?{color: $store.state.themeColor,fontWeight: 'bold'}:{}">{{item.name}}</li>
          </ul>
        </div>
        <div v-if="showMore" @click="showAllMenus" :style="{color: $store.state.themeColor,cursor: 'pointer'}">{{menuAllText}}</div>
    </div>
    <div class="search">
      <a-input v-model="keywords" placeholder="请输入课程名称..."/>
      <a-button @click="search" type="primary" :style="{background:$store.state.themeColor}">搜索</a-button>
    </div>
    <div class="loadding" v-show="!show">
      <a-spin />
    </div>
    <transition name="fades">
    <div v-if="show" class="list-wrap">
  <ul class="listOne" v-if="list.length > 0">
        <li class="tcItem" v-for="(item, i) in list" :key="i">
          <div class="img">
            <img class="img_a" :src="item.picture" alt="">
            <span v-show="item.type == 'live'" class="icon">直播</span>
          </div>
          <div class="info">
            <div class="top">
              <div style="display: inline-block;">
                <h3 class="title" @click="go(item, i)">{{item.title}}</h3>
              </div>
              <p class="describe">{{item.about}}</p>
              <span class="classTxt">总课时：{{item.period}}</span>
            </div>
            <div class="bot">
              <a-button v-if="item.type == 'normal'" @click="go(item, i)" size="large" :style="{background: studyBtnState(item.learn_status).bgColor, color: studyBtnState(item.learn_status).color, borderColor: studyBtnState(item.learn_status).bdColor}">{{studyBtnState(item.learn_status).msg}}</a-button>
              <a-button v-if="item.type == 'live'" @click="go(item, i)" size="large" :style="{background: '#ff8611', color: '#fff',borderColor: '#fff'}">查看课程</a-button>
            </div>
          </div>
        </li>
    </ul>
    <div class="no" v-else>
      <img src="../../assets/images/no.png" alt="">
      <p>暂无数据</p>
    </div>
    <a-pagination v-if="list.length != 0" @change="changePage" v-model="page" :total="totalPage" />
    </div>
    </transition>
</div>
</template>

<script>
import {
  first,
  second
} from '../../http/api'
export default {
    data() {
      return {
        show: false,
        index0: 0,
        index: 0,
        index2: 0,
        zero: [],
        first: [],
        second: [],
        third: [],
        third_cate_id: 0,
        list: [],
        firstId: 0,
        secondId: 0,
        page: 1,
        page_size: 10,
        totalPage: 0,
        keywords: '',
        zero_cate_id: 0,
        hostname: '',
        menuAllShow: false,
        menuAllText: '更多',
        showMore: false
      }
    },
    mounted() {
      if(this.$store.state.can_class == 1) {
        this.zero = [
          {
            id: 0,
            name: '培训课程'
          },
          {
            id: 1,
            name: '班级计划'
          }
        ]
      } else {
        this.zero = [
          {
            id: 0,
            name: '培训课程'
          }
        ]
      }
      this.hostname = window.location.hostname
      if(this.$route.query.type == 1) {
        this.index0 = this.$route.query.type
        this.zero_cate_id = this.$route.query.type
      }
      first().then(res => {
        if(res.data.code == 200) {
          this.first = res.data.data
          this.$nextTick(() => {
            let h = document.querySelector('.first-menus').scrollHeight
            if(h > 120) {
              this.showMore = true
            }
          })
        }
      })
      this.update(this.page)
    },
    methods: {
      showAllMenus() {
        if(!this.menuAllShow) {
          this.menuAllShow = true
          this.menuAllText = '收起'
        } else {
          this.menuAllShow = false
          this.menuAllText = '更多'
        }
      },
      search() {
        this.page = 1
        this.update(this.page)
      },
      menuUpdate(type) {
        first({
          zero_cate_id: type
        }).then(res => {
          if(res.data.code == 200) {
            this.first = res.data.data
          }
        })
      },
      zeroScreening(item, i) {
        this.index0 = i
        this.zero_cate_id = item.id
        this.menuUpdate(item.id)
        this.page = 1
        this.secondId = 0
        this.third_cate_id = 0
        this.index = 0
        this.index2 = 0
        this.second = []
        this.third = []
        this.keywords = ''
        this.update(this.page)
      },
      firstScreening(item, i) {
        this.index = i
        this.firstId = item.id
        this.page = 1
        this.secondId = 0
        this.index2 = 0
        this.keywords = ''
        if(item.id != 0) {
          first({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: item.id
          }).then(res => {
            if(res.data.code == 200) {
              this.second = res.data.data
            }
          })
        } else {
          this.second = []
          this.third = []
        }
        this.secondScreening({id: this.secondId})
      },
      secondScreening(item, i) {
        if(item.id != 0) {
          first({
              zero_cate_id: this.zero_cate_id,
              first_cate_id: this.firstId,
              second_cate_id: item.id
          }).then(res => {
              if(res.data.code == 200) {
                  this.third = res.data.data
              }
          })
        } else {
          this.third = []
        }
        this.index2 = i
        this.secondId = item.id
        this.third_cate_id = 0
        this.page = 1
        this.keywords = ''
        this.update(this.page)
      },
      thirdScreening(item, i) {
        this.third_cate_id = item.id
        this.page = 1
        this.keywords = ''
        this.update(this.page)
      },
      update(page) {
        this.show = false
        second({
          zero_cate_id: this.zero_cate_id,
          first_cate_id: this.firstId,
          second_cate_id: this.secondId,
          third_cate_id: this.third_cate_id,
          keywords: this.keywords,
          page: page,
          page_size: this.page_size
        }).then(res => {
          this.show = true
          if(res.data.code == 200) {
            this.list = res.data.data.courses
            this.totalPage = res.data.data.ext.total
          }
        }).catch(err => {
          this.show = true
          this.$message.error(err.response.data.message)
        })
      },
      go(item, i) {
          if(item.join_type == 'class') {
            this.$router.push({
              path: '/class/descript',
              query: {
                id: item.id
              }
            })
          } else {
            this.$router.push({
              path: '/course/studydetail',
              query: {
                id: item.id,
                course_type: item.course_type
              }
            })
          }
      },
      changePage(page, pageSize) {
        this.update(page)
      },
      studyBtnState(state) {
        if(state == 0) {
          let m = ''
          if(this.hostname == 'jxjy.zjttv.cn') {
            m = '查看课程'
          } else {
            m = '开始学习'
          }
          return {
            msg: m,
            bgColor: this.$store.state.themeColor,
            color: '#fff',
            bdColor: '#fff'
          }
        }else if(state == 1) {
          return {
            msg: '继续学习',
            bgColor: '#ff8611',
            color: '#fff',
            bdColor: '#fff'
          }
        } else if(state == 2) {
          return {
            msg: '已完成',
            bgColor: '#fff',
            color: this.$store.state.themeColor,
            bdColor: this.$store.state.themeColor
          }
        }
      }
    }

}
</script>

<style scoped>
button {
  width: 120px;
  font-size: 14px;
}
.lso {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.listOne {
  padding: 0 25px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.listOne .tcItem {
  display: flex;
  height: 240px;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #eee;
}
.listOne .tcItem:last-child {
  border-bottom: none;
}
.loadding {
  flex: 1;
}
.listOne .img {
  width: 330px;
  height: 190px;
  margin-right: 20px;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
}
.listOne .img:hover img {
  transform: scale(1.1);
}
.listOne .img img {
  transition: all .3s;
  object-fit: cover;
}
.listOne .info {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.listOne .top {
  flex: 1;
  width: 100%;
  text-align: left;
}
.listOne .bot {
  width: 100%;
  text-align: right;
}
.listOne .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
    margin-bottom: 20px;
}
.listOne .describe {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  color: #666;
  line-height: 22px;
}
.listOne .classTxt {
  color: #666;
}
 .menuWrap {
  border: 1px solid #eee;
  padding: 25px 25px 10px 25px;
  margin-bottom: 20px;
  border-radius: 4px;
}
 .menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
 .menu span {
  min-width: 70px;
  height: 20px;
  line-height: 20px;
}
 .menu ul{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
  flex-wrap: wrap;
  overflow: hidden;
  transition: all .8s;
}
.first-menus {
  max-height: 120px;
}
.menu-show-style {
  max-height: 1000px!important;
}
 .menu ul li {
  margin: 0 10px 10px;
  line-height: 14px;
  cursor: pointer;
  line-height: 20px;
  /* height: 20px; */
  color: #666;
  text-align: left;
  /* overflow: hidden;
  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; */
}
.icon {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
  }
  .search {
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  .search>input {
    margin-right: 10px;
    height: 40px;
    border-color: #eee;
  }
  .search>button {
    height: 40px;
  }
  .no {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no img {
    width: 100px;
    height: 100px;
  }
  .no p {
    color: #bbb;
  }
@media screen and (max-width: 1024px) {
  .listOne .tcItem {
    flex-direction: column;
    height: auto;
  }
  .listOne .img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .listOne .info {
    width: 100%;
  }
  .listOne {
    padding: 0 10px;
  }
  .search {
    width: 100%;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
  .listOne .img {
    height: 420px;
  }
}
</style>
