<template>
<div class="classes" :class="{'newbg':$store.state.tenant_idBind==3462}">
  <div class="top" style="height:68px">
    <img style="width: 418px;height: 38px;" src="../../assets/lenove/logo.png" alt="logo">
    <div class="search">
      <a-button @click="search" class="search-btn"></a-button>
      <a-input v-model="keywords" @keyup.enter="search" class="search-msg" placeholder="搜索" />

    </div>
  </div>
  <div class="bot">
    <div class="inner">
      <ul v-if="page_size===9" style="padding-left:1vw" class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
        <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :class="firstId == item.id?'active':'notactive'">{{item.name}}</li>
      </ul>
      <ul v-else-if="page_size===12" style="padding-left:0.75vw" class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
        <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :class="firstId == item.id?'active':'notactive'">{{item.name}}</li>
      </ul>
      <ul v-else-if="page_size===15" style="padding-left:0.6vw" class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
        <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :class="firstId == item.id?'active':'notactive'">{{item.name}}</li>
      </ul>
      <ul v-else-if="page_size===18" style="padding-left:0.5vw" class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
        <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :class="firstId == item.id?'active':'notactive'">{{item.name}}</li>
      </ul>
    </div>

    <!-- <div v-if="showMore" @click="showMoreMenu" class="menu-more">

                <img v-if="moreMenuFlag" src="../../assets/images/more_up.png" alt="">
                <img v-if="!moreMenuFlag" src="../../assets/images/more_down.png" alt="">
            </div> -->
  </div>

  <div class="classes-content">
    <div v-if="firstId != 0 && second.length > 0">
      <ul v-if="page_size===9" style="margin-left:1vw" class="second-menus">
        <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
      </ul>
      <ul v-if="page_size===12" style="margin-left:0.75vw" class="second-menus">
        <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
      </ul>
      <ul v-if="page_size===15" style="margin-left:0.6vw" class="second-menus">
        <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
      </ul>
      <ul v-if="page_size===18" style="margin-left:0.5vw" class="second-menus">
        <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
      </ul>

    </div>
    <!-- <ul class="third-menus" v-if="secondId != 0">
      <li @click="thirdScreening(item, i)" v-for="(item, i) in third" :key="i" :class="third_cate_id == item.id? 'ac': ''">{{item.name}}</li>
    </ul> -->
    <div class="loadding" v-show="!show">
      <a-spin />
    </div>
    <transition name="fades">
      <div v-if="show">
        <div v-if="list.length>0">
          <ul class="lesson-list" v-if="page_size===9">
            <li class="lesson-item" v-for="(item, i) in list" :key="i" @click="go(item, i)">
              <div class="content">
                <img  class="img_a" :src="item.picture" alt="">
                <div class="lesson-msg-wrap">
                  <p class="lesson-title">{{item.title}}</p>
                  <p class="lesson-about" v-if="item.type == 'link'">
                    课程来源：{{item.link_source}}
                  </p>
                  <div v-if="item.type != 'link'">
                    <p class="lesson-about">{{item.period}}课时 | {{item.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: item.learn_total}}人已学</p>

                    <div class="lesson-price-info">
                      <div v-if="item.author && item.author.length > 0">
                        <span v-for="(itemss, iss) in item.author.slice(0, 3)" :key="iss">{{itemss.name}}<span v-if="item.author.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="item.author.length>1 && item.author.length<3">{{iss != 1?'、': ''}}</span></span>
                      </div>
                      <p class="lesson-name" v-else></p>
                      <div v-if="priceShow()">
                        <!-- <span class="lesson-price-sign" v-if="item.price != 0">￥</span>
                        <span class="lesson-price">{{item.price > 0? item.price: '免费'}}</span> -->
                        <span class="lesson-price">免费</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="lesson-list" v-else-if="page_size===12">
            <li class="lesson-item4" v-for="(item, i) in list" :key="i" @click="go(item, i)">
              <div class="content">
                <img  class="img_a" :src="item.picture" alt="">
                <div class="lesson-msg-wrap">
                  <p class="lesson-title">{{item.title}}</p>
                  <p class="lesson-about" v-if="item.type == 'link'">
                    课程来源：{{item.link_source}}
                  </p>
                  <div v-if="item.type != 'link'">
                    <p class="lesson-about">{{item.period}}课时 | {{item.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: item.learn_total}}人已学</p>

                    <div class="lesson-price-info">
                      <div v-if="item.author && item.author.length > 0">
                        <span v-for="(itemss, iss) in item.author.slice(0, 3)" :key="iss">{{itemss.name}}<span v-if="item.author.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="item.author.length>1 && item.author.length<3">{{iss != 1?'、': ''}}</span></span>
                      </div>
                      <p class="lesson-name" v-else></p>
                      <div v-if="priceShow()">
                        <!-- <span class="lesson-price-sign" v-if="item.price != 0">￥</span>
                        <span class="lesson-price">{{item.price > 0? item.price: '免费'}}</span> -->
                        <span class="lesson-price">免费</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="lesson-list" v-else-if="page_size===15">
            <li class="lesson-item5" v-for="(item, i) in list" :key="i" @click="go(item, i)">
              <div class="content">
                <img  class="img_a" :src="item.picture" alt="">
                <div class="lesson-msg-wrap">
                  <p class="lesson-title">{{item.title}}</p>
                  <p class="lesson-about" v-if="item.type == 'link'">
                    课程来源：{{item.link_source}}
                  </p>
                  <div v-if="item.type != 'link'">
                    <p class="lesson-about">{{item.period}}课时 | {{item.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: item.learn_total}}人已学</p>

                    <div class="lesson-price-info">
                      <div v-if="item.author && item.author.length > 0">
                        <span v-for="(itemss, iss) in item.author.slice(0, 3)" :key="iss">{{itemss.name}}<span v-if="item.author.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="item.author.length>1 && item.author.length<3">{{iss != 1?'、': ''}}</span></span>
                      </div>
                      <p class="lesson-name" v-else></p>
                      <div v-if="priceShow()">
                        <!-- <span class="lesson-price-sign" v-if="item.price != 0">￥</span>
                        <span class="lesson-price">{{item.price > 0? item.price: '免费'}}</span> -->
                        <span class="lesson-price">免费</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="lesson-list" v-else-if="page_size===18">
            <li class="lesson-item6" v-for="(item, i) in list" :key="i" @click="go(item, i)">
              <div class="content">
                <img  class="img_a" :src="item.picture" alt="">
                <div class="lesson-msg-wrap">
                  <p class="lesson-title">{{item.title}}</p>
                  <p class="lesson-about" v-if="item.type == 'link'">
                    课程来源：{{item.link_source}}
                  </p>
                  <div v-if="item.type != 'link'">
                    <p class="lesson-about">{{item.period}}课时 | {{item.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: item.learn_total}}人已学</p>

                    <div class="lesson-price-info">
                      <div v-if="item.author && item.author.length > 0">
                        <span v-for="(itemss, iss) in item.author.slice(0, 3)" :key="iss">{{itemss.name}}<span v-if="item.author.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="item.author.length>1 && item.author.length<3">{{iss != 1?'、': ''}}</span></span>
                      </div>
                      <p class="lesson-name" v-else></p>
                      <div v-if="priceShow()">
                        <!-- <span class="lesson-price-sign" v-if="item.price != 0">￥</span>
                        <span class="lesson-price">{{item.price > 0? item.price: '免费'}}</span> -->
                        <span class="lesson-price">免费</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="no" v-else>
          <img src="../../assets/images/no.png" alt="">
          <p>暂无数据</p>
        </div>
      </div>
    </transition>
    <a-pagination v-if="list.length > 0" @change="changePage" v-model="page" :pageSize="page_size" :total="totalPage" />
  </div>

</div>
</template>

<script>
import {
  first,
  second
} from '../../http/api'
import merge from 'webpack-merge'
export default {
  data() {
    return {
      value: 5,
      page: 1,
      page_size: 9,
      totalPage: 0,
      keywords: '',
      first: [],
      second: [],
      third: [],
      zero_cate_id: 0,
      firstId: 0,
      secondId: 0,
      third_cate_id: 0,
      list: [],
      show: false,
      showMore: false,
      moreMsg: '更多',
      moreMenuFlag: true,
      firstLoadFinish: false

    }
  },
  mounted() {
    window.onresize = this.onPageSize
    this.onPageSize()
    this.onloadPageSize()

  },
  watch: {
    page_size() {
      if (this.firstLoadFinish) {
        this.page = 1
        this.update(this.page)
      }
    }
  },
  methods: {
    async onloadPageSize() {
      this.secondId = this.$route.query.second_cate_id ? this.$route.query.second_cate_id : 0
      this.third_cate_id = this.$route.query.third_cate_id ? this.$route.query.third_cate_id : 0
      console.log(this.zero_cate_id);
      const res = await first({
        zero_cate_id: this.zero_cate_id
      })
      if (res.data.code == 200) {
        this.first = res.data.data
        if (this.$store.state.lenovo_authorized) {
          this.firstId = this.$route.query.id ? this.$route.query.id : res.data.data[0].id
        } else {
          this.firstId = this.$route.query.id ? this.$route.query.id : 0
        }
        this.$nextTick(() => {
          let h = document.querySelector('.first-menus').scrollHeight
          if (h > 60) {
            this.showMore = true
          }
        })
        if (this.firstId) {
          const res1 = await first({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId
          })
          if (res1.data.code == 200) {
            this.second = res1.data.data
          }
        }

        if (this.secondId) {
          const res2 = await first({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId,
            second_cate_id: this.secondId
          })
          if (res2.data.code == 200) {
            this.third = res2.data.data
          }
        }
        this.update(this.page)
      }
    },
    onPageSize() {
      if (document.body.clientWidth < 1200) {
        this.page_size = 9;
      } else if (document.body.clientWidth < 1600) {
        this.page_size = 12;
      } else if (document.body.clientWidth < 2000) {
        this.page_size = 15;
      } else {
        this.page_size = 18
      }
    },
    priceShow() {
    console.log('this.$store.state.tenant_idBind',this.$store.state.tenant_idBind)    

      if (this.$store.state.tenant_idBind == 1222) {
        return false
      //} else if(this.$store.state.can_pay && this.$store.state.tenant_idBind != 22) {
        } else if(this.$store.state.can_pay) {
        return true
      }
    },
    showMoreMenu() {
      if (!this.moreMenuFlag) {
        this.moreMsg = '收起'
        this.moreMenuFlag = true
      } else {
        this.moreMsg = '更多'
        this.moreMenuFlag = false
      }
    },
    async firstScreening(item, i) {
      this.$router.push({
        query: merge(this.$route.query, {
          id: item.id
        })
      })
      this.firstId = item.id
      this.page = 1
      this.secondId = 0
      this.keywords = ''
      const res = await first({
        zero_cate_id: this.zero_cate_id,
        first_cate_id: item.id
      })
      if (res.data.code == 200) {
        this.second = res.data.data
      }
      this.secondScreening({
        id: this.secondId
      })
    },
    async secondScreening(item, i) {
      this.$router.push({
        query: merge(this.$route.query, {
          second_cate_id: item.id
        })
      })
      const res = await first({
        zero_cate_id: this.zero_cate_id,
        first_cate_id: this.firstId,
        second_cate_id: item.id
      })

      if (res.data.code == 200) {
        this.third = res.data.data
      }
      this.secondId = item.id
      this.third_cate_id = 0
      this.page = 1
      this.keywords = ''
      this.update(this.page)
    },
    thirdScreening(item, i) {
      this.$router.push({
        query: merge(this.$route.query, {
          third_cate_id: item.id
        })
      })
      this.third_cate_id = item.id
      this.page = 1
      this.keywords = ''
      this.update(this.page)
    },
    async update(page) {
      this.show = false
      const res = await second({
        zero_cate_id: this.zero_cate_id,
        first_cate_id: this.firstId,
        second_cate_id: this.secondId,
        third_cate_id: this.third_cate_id,
        keywords: this.keywords,
        page: page,
        page_size: this.page_size,
        type: 1
      })
      this.show = true
      if (res.data.code == 200) {
        this.firstLoadFinish = true
        this.list = res.data.data.courses
        this.totalPage = res.data.data.ext.total
      }
    },
    changePage(page, pageSize) {
      this.update(page)
    },
    search() {
      this.page = 1
      this.update(this.page)
    },
    go(item, i) {
      if (item.course_type == 'system_course') {
        this.$router.push({
          path: '/lenovelcourse',
          query: {
            id: item.id,
            course_type: item.course_type
          }
        })
      } else {
        if (item.type == 'link') {
          window.open(item.link_address)
        } else {
          this.$router.push({
            path: '/lenovelcourse',
            query: {
              id: item.id,
              course_type: item.course_type
            }
          })
        }
      }

    },
  }
}
</script>

<style scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classes-bg {
  background: url('../../assets/images/class-bg.png') no-repeat;
  background-size: cover;
  padding: 40px 0;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner {
  width: 100vw;
  margin: 0 auto;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
}

.title-wrap img {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}

.title-wrap h3 {
  font-size: 30px;
  margin-bottom: 0;
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  /* -webkit-background-clip: text; */
  color: transparent;
}

.search {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 4px;
  height: 30px;
  width: 244px;
  margin-right: 24px;
  background: #F3F3F3;
  overflow: hidden;
}

.search-msg {
  /* width: 400px; */
  flex: 1;
  background: #F3F3F3;
  height: 30px;
  border: none;
  border-radius: 0;
}

.search-btn {
  width: 30px;
  height: 30px;
  background: url('../../assets/lenove/search.png') no-repeat;
  border: none;
  border-radius: 0;
}

.classes-content {
  padding: 13px 0 40px;
  width: 100vw;
  margin: 0 auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.lesson-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.lesson-item {
  width: 33.3vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 1vw;
}

.lesson-item .content {
  background: #FAFAFA;
  text-align: left;
  padding: 1vw;
  cursor: pointer;
}

.lesson-item .content:hover {
  box-shadow: 0 0 5px #ddd;
}

.lesson-item .content img {
  height: 16vw;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}

.lesson-item4 {
  width: 25vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.75vw;
}

.lesson-item4 .content {
  background: #FAFAFA;
  text-align: left;
  padding: 0.75vw;
  cursor: pointer;
}

.lesson-item4 .content:hover {
  box-shadow: 0 0 5px #ddd;
}

.lesson-item4 .content img {
  height: 12vw;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}

.lesson-item5 {
  width: 20vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.6vw;
}

.lesson-item5 .content {
  background: #FAFAFA;
  text-align: left;
  padding: 0.6vw;
  cursor: pointer;
}

.lesson-item5 .content:hover {
  box-shadow: 0 0 5px #ddd;
}

.lesson-item5 .content img {
  height: 10vw;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}

.lesson-item6 {
  width: 16.6vw;
  height: auto;
  /* margin-right: 21px; */
  background: #fff;
  /* margin-bottom: 20px; */
  padding: 0.5vw;
}

.lesson-item6 .content {
  background: #FAFAFA;
  text-align: left;
  padding: 0.5vw;
  cursor: pointer;
}

.lesson-item6 .content:hover {
  box-shadow: 0 0 5px #ddd;
}

.lesson-item6 .content img {
  height: 8vw;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}

.lesson-title {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.lesson-about {
  margin-bottom: 22px;
  font-size: 12px;
  line-height: 20px;
  color: #727275;
}

/* .lesson-name {
  min-height: 18px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
} */

.lesson-price-info span {
  /* /* height: 12px; */
  line-height: 18px;
  font-size: 12px;
  color: #727275
}

.lesson-price-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-msg-wrap {
  background: #FAFAFA;
}

.lesson-price-sign {
  color: #FF5E57 !important;
}

.lesson-price {
  color: #FF9B38 !important;
  font-size: 16px !important;
  /* line-height: 18px; */
  font-weight: bold;
}

.bot {
  /* box-shadow: 0 3px 5px #ccc; */
}

.bot .first-menus {
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: 1s all;
  /* padding-top: 18px; */
  padding: 10px 0px;
  border-bottom: 1px solid #F3F3F3;
  border-top: 1px solid #F3F3F3;
}

.bot .first-menus .notactive {

  color: #434347;
  /* font-weight: bold; */
  font-size: 14px;
  margin-right: 34px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
}

.bot .first-menus .notactive::after {
  content: "";
  width: 30px;
  height: 2px;
  margin: 0 auto;
  display: block;
  border: 2px solid #fff;
}

.bot .first-menus .active {

  color: #0063B1;
  /* font-weight: bold; */
  font-size: 14px;
  margin-right: 34px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
}

.bot .first-menus .active::after {
  content: "";
  width: 30px;
  height: 2px;
  margin: 0 auto;
  display: block;
  border: 2px solid #0063B1;
}

.second-menus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: 1s all;
  /* border-bottom: 1px solid #ddd; */
  /* margin-bottom: 20px; */
}

.second-menus li {
  padding: 0 10px;
  /* height: 36px; */
  background: #fff;
  color: #000;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 28px;
  text-align: left;
}

.third-menus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: 1s all;
  margin-bottom: 20px;
}

.third-menus li {
  padding: 0 10px;
  /* height: 36px; */
  background: #fff;
  color: #333;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  line-height: 36px;
  text-align: left;
}

.loadding {
  flex: 1;
}

.ac-bg {
  color: #fff !important;
  background: #0063B1 !important;
  border-radius: 4px;
  /* font-weight: bold; */
}

.ac {
  color: #0063B1 !important;
  /* font-weight: bold; */
}

.no {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no img {
  width: 100px;
  height: 100px;
}

.no p {
  color: #bbb;
}

.menu-more {
  color: #666;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-more img {
  width: 28px;
  height: 26px;
  padding: 5px;
  cursor: pointer;
}
</style>
