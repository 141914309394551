<template>
    <div class="wechat">
        <div v-show="!show">
            <a-spin tip="请稍后..."/>
        </div>
        <p v-show="show">{{bindTxt}}</p>
        <div class="checkPhone" v-show="!$store.state.token && show">
            <div class="checkItem">
                <div class="formItem">
                    <label for=""><span class="red">*</span>手机号：</label>
                    <a-input style="width: 252px" @keyup="checkUser()" v-model="username" placeholder="请输入手机号" />
                </div>

                <div v-show="loginModalCode == 200">
                    <div class="formItem">
                        <label for=""><span class="red">*</span>手机验证码</label>
                        <a-input placeholder="验证码"  class="codeNumber" v-model="codeNumber"/>
                        <a-button class="sendBtn" @click="send()" :disabled="isSend" :style="{background: !isSend?$store.state.themeColor:'#ccc', color: '#fff'}" size="large">{{sendTxt}}</a-button>
                    </div>
                </div>
                
                <a-button class="next" :disabled="loginModalCode != 200 || !btnFlag" @click="next()" :style="{background: loginModalCode != 200? '#eee': $store.state.themeColor,color: '#fff',width: '120px',height: '40px',width: '100%'}">绑定</a-button>
            </div>

        </div>

        <loginModal
        :loginModalCode="loginModalCode"
        :loginModalTitle="loginModalTitle"
        :loginModalEnterpriseName="loginModalEnterpriseName"
        :loginModalBtnMsg="loginModalBtnMsg"
        :loginModalVisible="loginModalVisible"
        :loginModalMobile="loginModalMobile"
        @loginModalOk="loginModalOk"
        @loginModalCancel="loginModalCancel"></loginModal>

        <a-modal v-model="codeVisible"
        :centered="true"
        :footer="null"
        :destroyOnClose="true"
        :maskClosable="false"
        width="400px"
        :bodyStyle="{
            zIndex: '99999999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px'
        }">
            <div id="sc"></div>
        </a-modal>
    </div>
</template>

<script>
import {
    getWechat,
    resetSend,
    yzm,
    checkPhoneWechat,
    bindWechat,
    bindChange,
    dealTenant
} from '../../http/api'
import loginModal from '../../components/login_modal/index'
export default {
    data() {
        return {
            codeVisible: false,
            validationData: [],
            username: '',
            codeNumber: '',
            userState: 0,
            unionid: "",
            bindTxt: "",
            show: false,
            sendTxt: "获取验证码",
            isSend: false,
            loginModalTitle: '',
            loginModalBtnMsg: '',
            loginModalVisible: false,
            loginModalCode: '',
            loginModalEnterpriseName: '',
            btnFlag: false,
            loginModalMobile: '',
            msgEnd: true,
        }
    },
    components: {
        loginModal
    },
    created() {
        this.codeInit('ic_login')
        getWechat({
            authclient: 'weixin',
            scope: 'snsapi_login',
            code: window.location.search.split('&')[0].split('=')[1],
            token: this.$store.state.token,
            jump: window.location.hostname
        }).then(res => {
            if(res.data.code == 101) {
                this.show = false
                if(this.$store.state.can_face && this.$store.state.login_face) { // 方式人脸识别回调刷新页面，导致微信登录失效
                    // this.$message.error(res.data.message)
                    this.$router.push('/')
                } else {
                    // this.$message.error(res.data.message)
                    this.$router.push('/')
                }
            } else if(res.data.code == 110) {// 允许加入企业的开关打开后（提示用户不是当前企业用户，并加入当前企业）
                this.$store.commit("SETTOKEN", res.data.data.token)
                this.$store.commit("SETUSERNAME", res.data.data.username)
                this.$store.commit("SETREALNAME", res.data.data.realname)
                this.$store.commit("SETAVATAR", res.data.data.avatar)
                this.$store.commit("SHOWLOGIN", false)
                this.$store.commit("SETUUID", res.data.data.uuid)
                this.userName = ""
                this.passWord = ""
                localStorage.removeItem("firstPlayer")
                
                this.loginModalCode = res.data.code.toString()
                this.loginModalVisible = true
                this.loginModalTitle = res.data.message
                this.loginModalBtnMsg = '立即加入'
            } else {
                this.show = true
                this.unionid = res.data.data.unionid
                this.$store.commit("SAVEUNIONID", res.data.data.unionid)
                if(res.data.data.isBind == 1) {  // 绑定过，直接微信登录
                    if(this.$store.state.can_face && this.$store.state.login_face) {
                        let session = {
                            token: res.data.data.token,
                            username: res.data.data.username,
                            realname: res.data.data.realname,
                            avatar: res.data.data.avatar,
                            uuid: res.data.data.uuid,
                            idcard: res.data.data.idcard,
                            certification: res.data.data.certification,
                            type: 1 // 登录
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit('FACEVISIBLE', true)
                    } else {
                         if(res.data.code == 105) {  // 登录成功且用户不属于该企业，跳转到对应企业
                            this.$store.commit("DELETETOKEN")
                            this.$store.commit("SHOWLOGIN", false)
                            this.userName = ""
                            this.passWord = ""
                            localStorage.removeItem("firstPlayer")
                            this.loginModalCode = res.data.code.toString()
                            this.loginModalVisible = true
                            this.loginModalTitle = res.data.message
                            this.loginModalBtnMsg = '跳转'
                            this.hostname = res.data.data.hostname
                            this.loginModalEnterpriseName = res.data.data.website_name
                        }  else {
                            this.$store.commit("SETTOKEN", res.data.data.token)
                            this.$store.commit("SETUSERNAME", res.data.data.username)
                            this.$store.commit("SETREALNAME", res.data.data.realname)
                            this.$store.commit("SETAVATAR", res.data.data.avatar)
                            this.$message.success(res.data.message)
                            this.$router.push('/')
                        }
                    }
                } else if(res.data.data.isBind == 2) {  //个人中心换绑 或 绑定
                    bindChange({
                        unionid: this.unionid
                    }).then(res => {
                        if(res.data.code == 200) {
                            this.$message.success(res.data.message)
                            this.$router.push('/user/setuser')
                        } else if(res.data.code == 107) {
                            this.loginModalCode = res.data.code.toString()
                            this.loginModalMobile = res.data.data.mobile
                            this.loginModalVisible = true
                            this.loginModalTitle = res.data.message
                            this.loginModalBtnMsg = '确定'
                            this.bindTxt = res.data.message
                            this.show = true
                        }
                    })
                }
            }
        })
    },
    mounted() {
    },
    methods: {
        codeInit(param) {
            window.NVC_Opt = {
                appkey: "FFFF0N00000000009E07",
                scene: param,
                trans: {"key1": "code0", "nvcCode":200},
                elements: [
                    "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png",
                    "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png"
                ], 
                bg_back_prepared: "//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png",
                bg_front: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC",
                obj_ok: "//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png",
                bg_back_pass: "//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png",
                obj_error: "//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png",
                bg_back_fail: "//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png",
                upLang:{"cn":{
                    _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
                    _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
                    _ggk_loading: "加载中",
                    _ggk_fail: ["呀，盾牌不见了<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                    _ggk_action_timeout: ["我等得太久啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                    _ggk_net_err: ["网络实在不给力<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                    _ggk_too_fast: ["您刮得太快啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"]
                    }
                }
            }
        },
        checkCommon() {
            checkPhoneWechat({
                mobile: this.username
            }).then(res => {
                if(res.data.code == 200) {
                    this.loginModalCode = res.data.code.toString()
                    // this.keyTxt = "点击进行图形验证"
                    // this.keyState = false
                    document.querySelector("#captchaBoxBtn2").style.background = this.$store.state.themeColor
                } else if(res.data.code == 101) {
                    this.$message.error(res.data.message)
                } else if(res.data.code == 105) {  // 登录成功且用户不属于该企业，跳转到对应企业
                    this.$store.commit("DELETETOKEN")
                    this.$store.commit("SHOWLOGIN", false)
                    this.userName = ""
                    this.passWord = ""
                    localStorage.removeItem("firstPlayer")
                    this.loginModalCode = res.data.code.toString()
                    this.loginModalVisible = true
                    this.loginModalTitle = res.data.message
                    this.loginModalBtnMsg = '跳转'
                    this.hostname = res.data.data.hostname
                    this.loginModalEnterpriseName = res.data.data.website_name
                } else if(res.data.code == 106) {  // 手机号不存在
                    if(this.$store.state.isRegister) {
                        this.loginModalCode = res.data.code.toString()
                        this.loginModalVisible = true
                        this.loginModalTitle = '手机号不存在'
                        this.loginModalBtnMsg = '立即注册'
                    } else {
                        this.$message.error(res.data.message)
                    }
                } else {
                    this.$message.error(res.data.message)
                }

            })
        },
        checkUser() {
            if(this.username.length == 11) {
                this.checkCommon()
                this.btnFlag = true
            } else {
                this.btnFlag = false
            }
        },
        send() {
            if(!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.username))){
                if(this.msgEnd) {
                    this.msgEnd = false
                    this.$message.error('手机号格式错误', () => {
                    this.msgEnd = true
                    })
                }
                return false
            } else if(!this.codeVisible) {
                this.codeVisible = true
                setTimeout(() => {
                    var ic = new smartCaptcha({
                    renderTo: '#sc',
                    width: 300,
                    height: 42,
                    default_txt: "点击按钮开始智能验证",
                    success_txt: "验证成功",
                    fail_txt: "验证失败，请在此点击按钮刷新",
                    scaning_txt: "智能检测中",
                    success: (data) => {
                        yzm({
                        session_id: data.sessionId,
                        token: NVC_Opt.token,
                        sig: data.sig,
                        scene: 'ic_register'
                        }).then(res => {
                            if(res.data.code == 200) {
                                resetSend({
                                    mobile: this.username,
                                    type: 5
                                }).then(res => {
                                    if(res.data.code == 200) {
                                        this.codeVisible = false
                                        this.phoneWrapShow = false
                                        this.forgetTitle = '获取验证码'
                                        this.codeWrapShow = true
                                        this.isSend = true
                                        this.$message.success(res.data.message)
                                        this.sendTxt = 59
                                        let time = setInterval(() => {
                                            if(this.sendTxt < 1) {
                                                this.sendTxt = "重新获取"
                                                this.isSend = false
                                                clearInterval(time)
                                            } else {
                                                this.sendTxt--
                                            }
                                        }, 1000)
                                    } else {
                                        this.codeVisible = false
                                        this.$message.error(res.data.message)
                                    }
                                })
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                    },
                    });
                    ic.init();
                }, 50)
            }
        },
        loginModalOk() {
            if(this.loginModalCode == 105) {
                if(process.env.NODE_ENV == 'release' || process.env.NODE_ENV == 'development') {
                window.location.href = 'https://' + this.hostname + '.newzj.adksedu.com'
                } else if(process.env.NODE_ENV == 'master') {
                window.location.href = 'https://' + this.hostname + '.zjttv.cn'
                }
            } else if(this.loginModalCode == 106) {
                this.loginModalVisible = false
                this.$store.commit("SHOWLOGIN", false)
                this.$store.commit("SHOWREGISTER", true)
            } else if(this.loginModalCode == 107) {
                this.loginModalVisible = false
            } else if(this.loginModalCode == 110) {  // 加入企业
                dealTenant({
                    platform_id: this.$store.state.product_idBind,
                    tenant_id: this.$store.state.tenant_idBind,
                    type: 0
                }).then(res => {
                    if(res.data.code == 200) {
                        this.$message.success(res.data.message)
                        this.loginModalVisible = false
                        this.$router.push('/')
                    }
                })
            }
        },
        loginModalCancel() {
            this.loginModalVisible = false
            if(this.loginModalCode == 110) {
                this.$store.commit("DELETETOKEN")
                localStorage.removeItem('state')
                this.$router.push("/")
            }
        },
        next() {
            if(this.username.length != 11) {
                this.$message.error("请输入正确的手机号！")
            } else {
                bindWechat({
                    mobile: this.username,
                    sms_code: this.codeNumber,
                    unionid: this.unionid
                }).then(res => {
                    if(res.data.code == 200) {
                        this.$message.success("绑定成功")
                        this.$store.commit("SETTOKEN", res.data.data.token)
                        this.$store.commit("SETUSERNAME", res.data.data.username)
                        this.$store.commit("SETAVATAR", res.data.data.avatar)
                        this.$router.push('/')
                    } else if(res.data.code == 101) {
                        this.$message.error(res.data.message)
                    } else if(res.data.code == 105) {  // 登录成功且用户不属于该企业，跳转到对应企业
                        this.$store.commit("DELETETOKEN")
                        this.$store.commit("SHOWLOGIN", false)
                        this.userName = ""
                        this.passWord = ""
                        localStorage.removeItem("firstPlayer")
                        this.loginModalCode = res.data.code.toString()
                        this.loginModalVisible = true
                        this.loginModalTitle = res.data.message
                        this.loginModalBtnMsg = '跳转'
                        this.hostname = res.data.data.hostname
                        this.loginModalEnterpriseName = res.data.data.website_name
                    } else if(res.data.code == 106) {  // 手机号不存在
                        if(this.$store.state.isRegister) {
                            this.loginModalCode = res.data.code.toString()
                            this.loginModalVisible = true
                            this.loginModalTitle = '手机号不存在'
                            this.loginModalBtnMsg = '立即注册'
                        } else {
                            this.$message.error(res.data.message)
                        }
                    } else if(res.data.code == 110) {// 允许加入企业的开关打开后（提示用户不是当前企业用户，并加入当前企业）
                        this.$store.commit("SETTOKEN", res.data.data.token)
                        this.$store.commit("SETUSERNAME", res.data.data.username)
                        this.$store.commit("SETREALNAME", res.data.data.realname)
                        this.$store.commit("SETAVATAR", res.data.data.avatar)
                        this.$store.commit("SHOWLOGIN", false)
                        this.$store.commit("SETUUID", res.data.data.uuid)
                        this.userName = ""
                        this.passWord = ""
                        localStorage.removeItem("firstPlayer")
                        
                        this.loginModalCode = res.data.code.toString()
                        this.loginModalVisible = true
                        this.loginModalTitle = res.data.message
                        this.loginModalBtnMsg = '立即加入'
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.wechat {
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkPhone {
    padding: 30px;
    box-shadow: 0 0 3px #ddd;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* .checkPhone .txt {
    color: #000;
    font-weight: bold;
    padding: 0 0 30px 0;
    margin-bottom: 0;
} */
.next {
    margin-top: 10px;
}
.formItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}
.codeNumber {
    width: 140px;
}
.formItem label {
    min-width: 80px;
    text-align: left;
    margin-right: 10px;
}
.checkItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkItem input {
    height: 40px;
}
#captchaBoxBtn2 {
    flex: 1;
    height: 40px;
}
.sendBtn {
    margin-left: 10px;
    font-size: 14px;
    min-width: 102px;
}
#sc {
  margin: 0;
}
.nc_scale {
  background: #e8e8e8 !important;
}
.nc_scale div.bc_bg {
  background: #7ac23c !important;
}
.nc_scale .scale_text2 {
  color: #fff !important;
}
.nc_scale span {
  border: 1px solid #ccc !important;
}
.errloading {
  border: #faf1d5 1px solid !important;
  color: #ef9f06 !important;
}
</style>