var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "course-catalog" },
    [
      _c("div", { staticClass: "title-class-all" }, [
        _c("span", { staticClass: "icon" }),
        _c("h3", [_vm._v("课程目录")]),
        _c("span", [_vm._v("共" + _vm._s(_vm.classes.course_num) + "门课")]),
      ]),
      _vm.course_learn_type && _vm.show
        ? _c(
            "a-timeline",
            _vm._l(_vm.classesArr, function (item, key, i) {
              return _c(
                "a-timeline-item",
                { key: i },
                [
                  _c("a-icon", {
                    staticStyle: { "font-size": "16px" },
                    attrs: { slot: "dot", type: "clock-circle-o" },
                    slot: "dot",
                  }),
                  _c("h3", { staticClass: "course-date" }, [
                    _vm._v(_vm._s(key)),
                  ]),
                  _c(
                    "ul",
                    _vm._l(item, function (items, keys, is) {
                      return _c(
                        "li",
                        { key: is, staticClass: "course-item" },
                        [
                          _c("img", { attrs: { src: items.picture, alt: "" } }),
                          _c("div", { staticClass: "course-infos" }, [
                            _c("div", [
                              _c("h3", [_vm._v(_vm._s(items.title))]),
                              _c("p", [_vm._v(_vm._s(items.about))]),
                            ]),
                            _c("span", [
                              _vm._v(
                                "课时数 " +
                                  _vm._s(items.period) +
                                  " | 视频数 " +
                                  _vm._s(items.lesson_num) +
                                  " | 课程总时长 " +
                                  _vm._s(items.length_format)
                              ),
                            ]),
                          ]),
                          _c(
                            "a-button",
                            {
                              style: {
                                background: _vm.btnStatusText(
                                  items.learn_status,
                                  items.is_expired,
                                  items.learn_status_text
                                ).background,
                                color: _vm.btnStatusText(
                                  items.learn_status,
                                  items.is_expired,
                                  items.learn_status_text
                                ).color,
                                borderColor: _vm.btnStatusText(
                                  items.learn_status,
                                  items.is_expired,
                                  items.learn_status_text
                                ).borderColor,
                                minWidth: "86px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goRecord(items)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.btnStatusText(
                                    items.learn_status,
                                    items.is_expired,
                                    items.learn_status_text
                                  ).val
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      !_vm.course_learn_type && _vm.show
        ? _c("div", { staticClass: "classes-wrap" }, [
            _c(
              "ul",
              _vm._l(_vm.classesArr, function (items, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "classes-item",
                    on: {
                      click: function ($event) {
                        return _vm.goRecord(items)
                      },
                    },
                  },
                  [
                    _c("img", { attrs: { src: items.picture, alt: "" } }),
                    _c("div", { staticClass: "classes-infos" }, [
                      _c("h3", [_vm._v(_vm._s(items.title))]),
                      _c("span", [
                        _vm._v(
                          "课时数 " +
                            _vm._s(items.period) +
                            " | 视频数 " +
                            _vm._s(items.lesson_num) +
                            " | 总时长 " +
                            _vm._s(items.length_format)
                        ),
                      ]),
                      _c("div", { staticClass: "progress" }, [
                        _c("span", [_vm._v("课程进度")]),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.classStatus(
                                items.learn_status,
                                items.finish_percent
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.classStatus(
                                  items.learn_status,
                                  items.finish_percent
                                ).name
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticStyle: { height: "300px", "line-height": "300px" },
        },
        [_c("a-spin")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }