<template>
    <div>
        <div class="bg">
            <div class="inner">
                <h3 class="bg-title">学分银行</h3>
                <h3 class="bg-subt">存储学习成果，助力未来人生</h3>
                <p>学分银行学历教育认定标准体系包括课程简介、课程与专业对应关系、课程与职业培训等证书对应关系等，为学分银行高校网点制定学分转换规定、学习者进行学分转换申请提供指导与参考。</p>
                <a-button class="btn" @click="go">查看你的学分银行</a-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        go() {
            this.$router.push({
                path: '/bankIndex/creditBank'
            })
        }
    }
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 760px;
    background: url('../../assets/images/bankIndexBg.jpg') no-repeat center center;
    background-size: cover;
}
.inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 60px;
}
.inner p {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn {
    widows: 160px;
    height: 46px;
    border-radius: 10px;
    background: #E99F0E;
    font-size: 15px;
    color: #fff;
    border: none;
}
.bg-title {
    font-size: 50px;
    color: #fff;
    font-weight: 500;
}
.bg-subt {
    font-size: 38px;
    background-image: -webkit-linear-gradient(top, #fff, #91bbe4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>