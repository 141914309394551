var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "examPaper" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _vm.show
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "titleWrap",
                  style: { background: _vm.$store.state.themeColor },
                },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.examResult.exam.name)),
                  ]),
                  _c("div", { staticClass: "score" }, [
                    _c("span", [
                      _vm._v("总分：" + _vm._s(_vm.examResult.exam.score)),
                    ]),
                    _c("span", [
                      _vm._v(
                        "及格分：" + _vm._s(_vm.examResult.exam.passed_score)
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "得分：" +
                          _vm._s(
                            _vm.examResult.result
                              ? _vm.examResult.result.score
                              : 0
                          )
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._l(_vm.options, function (item, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "examItem" },
                  [
                    _c("p", { staticClass: "itemTitle" }, [
                      _vm._v(_vm._s(i + 1) + "，" + _vm._s(item.title)),
                    ]),
                    _c("a-radio-group", {
                      attrs: {
                        name: item.id + "_" + item.question_id,
                        options: item.qesArr,
                      },
                      on: { change: _vm.onChange },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "btnWrap" },
                [
                  _c(
                    "a-button",
                    {
                      style: { background: _vm.$store.state.themeColor },
                      attrs: { size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.commitBtn()
                        },
                      },
                    },
                    [_vm._v("提交试卷")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { title: "", footer: "", maskClosable: false, centered: true },
          on: { cancel: _vm.photoCancel },
          model: {
            value: _vm.photoVisible,
            callback: function ($$v) {
              _vm.photoVisible = $$v
            },
            expression: "photoVisible",
          },
        },
        [
          _c("div", { staticClass: "photo-module" }, [
            _c("div", { staticClass: "photo-status-icon" }, [_vm._v("X")]),
            _c("div", { staticStyle: { flex: "1" } }, [
              _c("p", { staticClass: "photo-title" }, [_vm._v("请上传证件照")]),
              _c("p", { staticClass: "photo-des" }, [
                _vm._v(
                  "该课程绑定证书，且需要上传证件照，资料不全无法正常获取证书，"
                ),
              ]),
              _c("p", { staticClass: "photo-des" }, [
                _vm._v("请前往个人中心上传证件照。"),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "photo-do" },
            [
              _c(
                "a-button",
                {
                  style: {
                    background: _vm.$store.state.themeColor,
                    color: "#fff",
                    border: "none",
                  },
                  on: { click: _vm.photoHandleOk },
                },
                [_vm._v("去上传")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { color: "#333!important" },
                  on: { click: _vm.photoCancel },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }