<template lang="html">
  <div class="question">
    <h3 class="title" :style="{borderColor: $store.state.themeColor}">我的提问</h3>
    <ul class="list">
      <li v-for="item in list">
        <h3>{{item.content}}</h3>
        <!-- <p>外卖小哥确诊后外卖还能点吗？</p> -->
        <p>{{item.latest_post_time}}</p>
        <span class="icon">提问</span>
        <span class="icon2">{{item.course.title}}</span>
      </li>
    </ul>
    <a-pagination @change="change" :default-current="page" :total="total" :pageSize="page_size"/>
  </div>
</template>

<script>
import {
  myAsk
} from '../../http/api'
export default {
  data() {
    return {
      page: 1,
      page_size: 1,
      list: [],
      total: 0
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    change(page) {
      this.page = page
      this.list = []
      this.update()
    },
    update() {
      myAsk({
        page: this.page,
        page_size: this.page_size
      }).then(res => {
        if(res.data.code == 200) {
          this.total = res.data.ext.total
          res.data.data.map((item, i) => {
            this.list.push(item)
          })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.question {
  padding: 20px;
  border: 1px solid #eee;
}
.title {
  border-left: 4px solid #fff;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}
.list li {
  border: 1px solid #efefef;
  padding: 30px 30px 0;
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.list li:hover h3 {
  color: rgb(0, 113, 245);
}
.icon {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  width: 50px;
  height: 20px;
  line-height: 20px;
  background: rgb(0, 113, 245);
}
.icon2 {
  position: absolute;
  left: 55px;
  top: 0;
  padding:0 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  background: rgb(255, 134, 17);
}
.list li h3 {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
  transition: all .3s;
}
.list li p {
  text-align: left;
  color: #999;
  font-size: 12px;
}
</style>
