var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "answerPaper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.show,
            expression: "!show",
          },
        ],
        staticClass: "loadding",
      },
      [_c("a-spin")],
      1
    ),
    _vm.show
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "titleWrap",
              style: { background: _vm.$store.state.themeColor },
            },
            [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.answerResult.test.name)),
              ]),
              _c("div", { staticClass: "score" }, [
                _c("span", [
                  _vm._v("总分：" + _vm._s(_vm.answerResult.test.score)),
                ]),
                _c("span", [
                  _vm._v(
                    "及格分：" + _vm._s(_vm.answerResult.test.passed_score)
                  ),
                ]),
                _c("span", [
                  _vm._v("得分：" + _vm._s(_vm.answerResult.result.score)),
                ]),
              ]),
            ]
          ),
          _c(
            "ul",
            { staticClass: "answerList" },
            _vm._l(_vm.answerList, function (item, i) {
              return _c(
                "li",
                { key: i },
                [
                  _c("p", { staticClass: "itemTitle" }, [
                    _vm._v(_vm._s(i + 1 + "，" + item.name)),
                  ]),
                  _vm._l(item.ansObj.ansTxt, function (items, i) {
                    return _c("div", { key: i, staticClass: "itemWrap" }, [
                      _c("div", [
                        _vm._v(_vm._s(items) + " "),
                        i == item.ansObj.rightRes[1]
                          ? _c("span", { staticClass: "rIcon" }, [_vm._v("√")])
                          : _vm._e(),
                        i == item.ansObj.answer[1] &&
                        item.ansObj.status == "wrong"
                          ? _c("span", { staticClass: "wIcon" }, [_vm._v("x")])
                          : _vm._e(),
                      ]),
                    ])
                  }),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "a-button",
                {
                  style: { background: _vm.$store.state.themeColor },
                  attrs: { size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }