<template>
  <div class="notice">
    <div class="wrap">
      <List v-if="$store.state.template == 1" :type="null" :cate="id"></List>
      <ListTwo v-if="$store.state.template == 2" :type="null" :cate="id" :title="title"></ListTwo>
      <ListThr v-if="$store.state.template == 3" :type="null" :cate="cate" :title="title"></ListThr>
    </div>
  </div>
</template>

<script>
import List from '@/components/list/index.vue'
import ListTwo from '@/components/list/two.vue'
import ListThr from '@/components/list/thr.vue'
export default {
    data() {
        return {
          id: '',
          title: ''
        }
    },
    components: {
      List,
      ListTwo,
      ListThr
    },
    created() {
      this.id = parseInt(this.$route.query.id)
      this.title = this.$route.query.title
    }
}
</script>
<style scoped>
.wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>