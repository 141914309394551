import { render, staticRenderFns } from "./tem-two-course.vue?vue&type=template&id=2dc40be7&scoped=true"
import script from "./tem-two-course.vue?vue&type=script&lang=js"
export * from "./tem-two-course.vue?vue&type=script&lang=js"
import style0 from "./tem-two-course.vue?vue&type=style&index=0&id=2dc40be7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc40be7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/www/saas-adyxy-qypx-pc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dc40be7')) {
      api.createRecord('2dc40be7', component.options)
    } else {
      api.reload('2dc40be7', component.options)
    }
    module.hot.accept("./tem-two-course.vue?vue&type=template&id=2dc40be7&scoped=true", function () {
      api.rerender('2dc40be7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/study_list_template/tem-two-course.vue"
export default component.exports