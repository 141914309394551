var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bg" }, [
      _c(
        "div",
        { staticClass: "inner" },
        [
          _c("h3", { staticClass: "bg-title" }, [_vm._v("学分银行")]),
          _c("h3", { staticClass: "bg-subt" }, [
            _vm._v("存储学习成果，助力未来人生"),
          ]),
          _c("p", [
            _vm._v(
              "学分银行学历教育认定标准体系包括课程简介、课程与专业对应关系、课程与职业培训等证书对应关系等，为学分银行高校网点制定学分转换规定、学习者进行学分转换申请提供指导与参考。"
            ),
          ]),
          _c("a-button", { staticClass: "btn", on: { click: _vm.go } }, [
            _vm._v("查看你的学分银行"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }