var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setpassword" }, [
    _c(
      "h3",
      {
        staticClass: "title",
        style: { borderColor: _vm.$store.state.themeColor },
      },
      [_vm._v("基本信息")]
    ),
    _c("div", { staticClass: "content" }, [
      _c("ul", { staticClass: "form" }, [
        _c(
          "li",
          { staticClass: "item" },
          [
            _vm._m(0),
            _c("a-input", {
              attrs: {
                autocomplete: "new-password",
                type: "password",
                placeholder: "请输入旧密码",
              },
              model: {
                value: _vm.oldPassword,
                callback: function ($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "item" },
          [
            _vm._m(1),
            _c("a-input", {
              attrs: {
                autocomplete: "new-password",
                type: "password",
                placeholder: "请输入新密码",
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "item" },
          [
            _vm._m(2),
            _c("a-input", {
              attrs: {
                autocomplete: "new-password",
                type: "password",
                placeholder: "请确认新密码",
              },
              model: {
                value: _vm.newPassword,
                callback: function ($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "do" },
        [
          _c(
            "a-button",
            {
              style: {
                background:
                  _vm.one && _vm.two && _vm.three
                    ? _vm.$store.state.themeColor
                    : "#ccc",
              },
              attrs: {
                disabled: _vm.one && _vm.two && _vm.three ? false : true,
              },
              on: {
                click: function ($event) {
                  return _vm.changePasswordSubmit()
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "a-button",
            {
              staticClass: "resetBtn",
              on: {
                click: function ($event) {
                  return _vm.reset()
                },
              },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "" } }, [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _vm._v("旧密码"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "" } }, [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _vm._v("新密码"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "" } }, [
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
      _vm._v("确认密码"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }