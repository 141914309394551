<template>
        <div class="examPaper">
            <div class="loadding" v-show="!show">
                <a-spin />
            </div>
            <div v-if="show">
            <div class="titleWrap" :style="{background: $store.state.themeColor}">
                <h3 class="title">{{examResult.exam.name}}</h3>

                <div class="score">
                    <span>总分：{{examResult.exam.score}}</span>
                    <span>及格分：{{examResult.exam.passed_score}}</span>
                    <span>得分：{{examResult.result?examResult.result.score:0}}</span>
                </div>
            </div>

            <div class="examItem" v-for="(item, i) in options" :key="i">
                <p class="itemTitle">{{i+1}}，{{item.title}}</p>
                <a-radio-group :name="item.id+'_'+item.question_id" :options="item.qesArr" @change="onChange" v-model="item.value" />
            </div>
            <div class="btnWrap">
                <a-button size="large" @click="commitBtn()" :style="{background: $store.state.themeColor}">提交试卷</a-button>
            </div>
            </div>
            <a-modal v-model="photoVisible" @cancel="photoCancel" title="" footer="" :maskClosable="false" :centered="true">
                <div class="photo-module">
                    <div class="photo-status-icon">X</div>
                    <div style="flex: 1;">
                    <p class="photo-title">请上传证件照</p>
                    <p class="photo-des">该课程绑定证书，且需要上传证件照，资料不全无法正常获取证书，</p>
                    <p class="photo-des">请前往个人中心上传证件照。</p>
                    </div>
                </div>
                <div class="photo-do">
                    <a-button @click="photoHandleOk" :style="{background: $store.state.themeColor,color:'#fff',border: 'none'}">去上传</a-button>
                    <a-button @click="photoCancel" style="color: #333!important">返回</a-button>
                </div>
            </a-modal>
        </div>
</template>

<script>
import {
    getExam,
    commitResult,
} from '../../http/api'
export default {
    data() {
        return {
            options: [],
            item: {},
            examResult: {},
            show: false,
            photoVisible: false
        }
    },
    created() {

    },
    mounted() {

        getExam({
            id: this.$route.query.courseId,
            test_id: this.$route.query.testId,
            course_type: this.$route.query.course_type
        }).then(res => {
            if(res.data.code == 200) {
                this.examResult = res.data.data
                for(let i = 0;i<this.examResult.examItems.length;i++) {
                    let obj = {
                        title: this.examResult.examItems[i].question.stem,
                        id: this.examResult.examItems[i].id,
                        question_id: this.examResult.examItems[i].question_id
                    }
                    let arr2 = JSON.parse(this.examResult.examItems[i].question.metas).choices
                    let arr3 = []
                    for(let j = 0;j<arr2.length;j++) {
                        arr3.push({
                            label: arr2[j],
                            value: j
                        })
                    }
                    obj.qesArr = arr3
                    this.options.push(obj)
                }
                this.show = true
            } else if(res.data.code == 104) {
                this.photoVisible = true
            }

        }).catch(err => {

        })

    },
    methods: {
        photoHandleOk() {
          this.photoVisible = false
          this.$router.push('/user/setuser')
        },
        photoCancel() {
          this.photoVisible = false
          this.$router.go(-1)
        },
        onChange(e) {
          console.info(e)
            this.item[e.target.name] = e.target.value
            // console.info(this.item)
        },
        commitBtn() {
            commitResult({
                exam_id: this.examResult.exam.id,
                begin_time: this.getT(),
                exam: this.item
            }).then(res => {
                if(res.data.code == 200) {
                    this.$message.success(res.data.message)
                    this.result_id = res.data.data.result_id
                    setTimeout(() => {
                        this.$router.push({
                            path: '/answerPaper',
                            query: {
                                result_id: this.result_id,
                                courseId: this.$route.query.courseId,
                                course_type: this.$route.query.course_type
                            }
                        })
                    },600)

                } else {
                    this.$message.error(res.data.message)
                }
            }).catch(err => {

        })
        },
        getT() {
            let year = new Date().getFullYear(),
            month = new Date().getMonth(),
            day = new Date().getDate()
            return year +'-'+ (month+1) +'-'+ day
        }
    }

}
</script>

<style scoped>
button {
    color: #fff!important;
}
.examPaper {
    width: 1000px;
    margin: 0 auto;
    text-align: left;
}
.examPaper .examItem {
    margin-bottom: 20px;
}
.examPaper .title {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.examPaper .itemTitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.examPaper .btnWrap {
    text-align: center;
    padding: 30px 0;
}
.examPaper .titleWrap {
    height: 120px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.examPaper .score {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
}
.examPaper .score span {
    font-size: 16px;
}
@media screen and (max-width: 1024px) {
    .examPaper {
        width: 100%;
    }
}
</style>
