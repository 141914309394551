<template>
    <div class="course-catalog">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>课程目录</h3>
            <span>共{{classes.course_num}}门课</span>
        </div>
        <!-- <div class="prev_course" v-if="show && prev_course">
            <img :src="prev_course.picture" alt="">
            <div>
                <h3>{{prev_course.title}}</h3>
                <p>{{prev_course.about}}</p>
                <span>已学到 {{prev_course.finish_percent}}%</span>
            </div>
            <a-button @click="goRecord(prev_course)">继续学习</a-button>
        </div> -->
        <a-timeline v-if="course_learn_type && show">
            <a-timeline-item v-for="(item, key, i) in classesArr" :key="i">
                <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                <h3 class="course-date">{{key}}</h3>
                <ul>
                    <li class="course-item" v-for="(items, keys, is) in item" :key="is">
                        <img :src="items.picture" alt="">
                        <div class="course-infos">
                            <div>
                                <h3>{{items.title}}</h3>
                                <p>{{items.about}}</p>
                            </div>
                            <span>课时数 {{items.period}} | 视频数 {{items.lesson_num}} | 课程总时长 {{items.length_format}}</span>
                        </div>
                        <a-button @click="goRecord(items)" 
                        :style="{background: btnStatusText(items.learn_status, items.is_expired, items.learn_status_text).background, color: btnStatusText(items.learn_status, items.is_expired, items.learn_status_text).color,borderColor: btnStatusText(items.learn_status, items.is_expired, items.learn_status_text).borderColor,minWidth: '86px'}"
                        >{{btnStatusText(items.learn_status, items.is_expired, items.learn_status_text).val}}</a-button>
                    </li>
                </ul>
            </a-timeline-item>
        </a-timeline>
        <div class="classes-wrap" v-if="!course_learn_type && show">
            <ul>
                <li class="classes-item" v-for="(items, i) in classesArr" :key="i" @click="goRecord(items)">
                    <img :src="items.picture" alt="" >
                    <div class="classes-infos">
                        <h3>{{items.title}}</h3>
                        <span>课时数 {{items.period}} | 视频数 {{items.lesson_num}} | 总时长 {{items.length_format}}</span>
                        <div class="progress">
                            <span>课程进度</span>
                            <span :style="{color: classStatus(items.learn_status, items.finish_percent).color}">{{classStatus(items.learn_status, items.finish_percent).name}}</span>
                        </div>
                    </div>
                    
                </li>
            </ul>
        </div>
        <div style="height: 300px;line-height: 300px;" v-show="!show">
            <a-spin />
        </div>
    </div>
</template>

<script>
import {
    getPlan,
    userInfo,
    classDetail
} from '../../http/api'
export default {
    data() {
        return {
            class_id: '',
            course_learn_type: '',
            classesArr: '',
            show: false,
            prev_course: {},
            total_pages: 0,
            isToken:true,//判断token是否有效
            classData:{}
        }
    },
    props: {
        classes: {}
    },
    created() {
       

        this.class_id = this.$route.query.id
        getPlan({
            id: this.class_id,
            page: 1,
            page_size: 20
        }).then(res => {
            if(res.data.code == 200) {
                this.total_pages = res.data.data.pages.total_page
                this.course_learn_type = res.data.data.course_learn_type
                this.classesArr = res.data.data.class
                this.prev_course = res.data.data.prev_course
                this.show = true
            }
        })
    },
    mounted() {
    console.log('this.$store.state.class_id',this.$store.state.class_id)
    console.log('this.$store.state.is_class_certificate',this.$store.state.is_class_certificate)
    // this.getClassDetail();
      let page = 2
      let flag = true
      window.onscroll = () => {
        let seeH = document.body.offsetHeight
        let docH = document.body.scrollHeight
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if(this.$route.path == '/class/catalog') {
          if(this.$store.state.template == 1) {
            if(scrollTop > 180) {
              this.$store.commit("ISINDEX", false)
            } else {
              this.$store.commit("ISINDEX", true)
            }
          } else if(this.$store.state.template == 2) {
            if(scrollTop > 120) {
              this.$store.commit("ISINDEX", false)
            } else {
              this.$store.commit("ISINDEX", true)
            }
          }

          if(docH - (seeH + scrollTop) < 800) {
            if(flag && this.total_pages > 1) {
              flag = false
              getPlan({
                id: this.class_id,
                page: page,
                page_size: 20
              }).then(res => {
                if(res.data.code == 200) {
                  res.data.data.class.map((item, i) => {
                    if(res.data.data.class.length > 0) {
                      this.classesArr.push(item)
                    }
                  })
                  if(page < res.data.data.pages.total_page) {
                    flag = true
                    page++
                  }
                }
              })
            }
          } 
        }
      }
    },
    methods: {
        getClassDetail(){
            console.log('2222222222222')
            classDetail({
                id: this.$route.query.id
            }).then(res => {
                if(res.data.code == 200) {
                    let data = res.data.data.class;
                    this.classData = data;
                }
            })
        },
       async getUserInfo(){
            let that = this;
           await userInfo().then(res => {
            
                }).catch(err => {
                    that.isToken = false;
                    that.$message.error('学员账号登录状态已失效，请登录后重试');
            })
        },
       async goRecord(items) {
           console.log('itemssss',items)
           console.log('this.classData',this.classData)
           console.log('this.$store.state.is_class_certificate213123',this.$store.state.is_class_certificate=='')
           if(this.$store.state.is_class_certificate===2){//此逻辑后端等同于this.classes.is_forever == 0
              this.$message({
                    message: '班级未开始，请关注班级开始时间！',
                    type: 'warning'
                });
              return;
          }
           if(items.is_locked){
                this.$message({
                    message: '需先完成前置课程学习和考试，才能学习该课程',
                    type: 'warning'
                });
                return;
            }
           if(this.$store.state.is_class_certificate===0){
              let sessionObj = JSON.parse(sessionStorage.session)
              let session = {
                certification: sessionObj.certification,
                token: sessionObj.token,
                type: 4, //班级人脸
                class_id:this.classes.id,
                callback:'/course/studydetail?id='+items.id+'&course_type='+items.course_type
              }
              let sessionStr = JSON.stringify(session)
              sessionStorage.setItem('session', sessionStr)
              this.$store.commit("SETTOKEN", sessionObj.token)
              this.$store.commit('FACEVISIBLE', true)
              return;
          }
           
            if(this.classes.is_forever == 0) {
                // if(new Date(this.classes.end_time).getTime() - new Date().getTime() < 0) {
                //     this.$message.error('班级已过期，无法学习')
                //     return false
                // } else
                if(new Date(this.classes.begin_time).getTime() - new Date().getTime() > 0) {
                    this.$message.error('未开班，暂不支持学习')
                    return false
                }
            }
                console.log('this.classes',this.classes)

            if(this.classes.order_status == 0) {
                let that = this;
                await this.getUserInfo();
                console.log('isToken',this.isToken)
                if(this.isToken){
                    this.$message.error('未购买班级，不能查看')

                }
                
                return false
            }
            if(!items.is_expired) {
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                        id: items.id,
                        course_type: items.course_type,
                        class_id: this.classes.id
                    }
                })
            } else if(items.is_expired == 1 && items.learn_status ==2){
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                        id: items.id,
                        course_type: items.course_type,
                        class_id: this.classes.id
                    }
                })
            } else if(items.is_expired == 2) {
                this.$message.error('课程未开始，请关注课程开始时间')
            } else {
                 this.$router.push({
                    path: '/course/studydetail',
                    query: {
                        id: items.id,
                        course_type: items.course_type,
                        class_id: this.classes.id
                    }
                })
                // this.$message.error('课程已过期，无法学习，请联系管理员处理')
            }
            
        },
        btnStatusText(learn_status, is_expired, learn_status_text) {
            if(learn_status == 2) {
                return {
                    val: '已完成',
                    background: '#fff',
                    color: this.$store.state.themeColor,
                    borderColor: this.$store.state.themeColor
                }
            } else if(learn_status != 2 && is_expired == 0){
                return {
                    val: learn_status_text,
                    background: this.$store.state.themeColor,
                    color: '#fff',
                    borderColor: this.$store.state.themeColor
                }
            } else if(is_expired == 1) {
                return {
                    val: '已过期',
                    background: '#ccc',
                    color: '#fff',
                    borderColor: '#fff'
                }
            } else if(is_expired == 2) {
                return {
                    val: '未开始',
                    background: '#ccc',
                    color: '#fff',
                    borderColor: '#fff'
                }
            }
        },
        classStatus(learn_status, finish_percent) {
            if(learn_status == 0) {
                return {
                    name: '未学习',
                    color: '#ccc'
                }
            } else if(learn_status == 1) {
                return {
                    name: finish_percent+'%',
                    color: '#fe8e21'
                }
            } else if(learn_status == 2) {
                return {
                    name: '已完成',
                    color: '#52da4b'
                }
            }
        }
    }
}
</script>

<style scoped>
.course-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    margin-bottom: 15px;
}
.course-item img {
    width: 240px;
    height: 150px;
    margin-right: 20px;
    object-fit: cover;
}
.course-date {
    font-weight: bold;
}
.course-infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 150px;
}
.course-infos h3 {
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.course-infos p {
    font-size: 14px;
    color: #666;
}
.course-infos span {
    color: #666;
    font-size: 12px;
}
.classes-wrap ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.classes-item img{
    width: 100%;
    height: 142px;
    object-fit: cover;
}
.classes-item {
    margin-right: 6px;
    width: 240px;
    margin-bottom: 12px;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
}
.classes-item:nth-child(4n) {
    margin-right: 0;
}
.classes-infos {
    max-height: 90px;
    min-height: 90px;
    padding:4px;
    background: #FAFAFA;
}
.classes-infos h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}
.classes-infos span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 12px;
    color: #666;
    display: block;
    line-height: 12px;
    margin-bottom: 16px;
    text-align: left;
}
.classes-item .progress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.classes-item .progress>span {
    margin-right: 20px;
}
.prev_course {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F3F6F7;
    padding: 10px 30px 10px 10px;
    margin-bottom: 30px;
    border-radius: 4px;
    margin-left: 28px;
}
.prev_course img {
    width: 240px;
    height: 150px;
    margin-right: 20px;
}
.prev_course>div {
    height: 150px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}
.prev_course>div h3 {
    font-size: 16px;
    font-weight: bold;
}
.prev_course>div p {
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.prev_course>div span {
    font-size: 12px;
    color: #666;
}
.prev_course button {
    background: #FF8611;
    color: #fff;
    border-color: #FF8611;
}
</style>