<template>
        <div class="answerPaper">
            <div class="loadding" v-show="!show">
                <a-spin />
            </div>
            <div v-if="show">
            <div class="titleWrap" :style="{background: $store.state.themeColor}">
                <h3 class="title">{{answerResult.test.name}}</h3>

                <div class="score">
                    <span>总分：{{answerResult.test.score}}</span>
                    <span>及格分：{{answerResult.test.passed_score}}</span>
                    <span>得分：{{answerResult.result.score}}</span>
                </div>
            </div>

            <ul class="answerList">
                <li v-for="(item, i) in answerList" :key="i">
                    <p class="itemTitle">{{(i+1)+'，'+item.name}}</p>
                    <!-- <div class="itemWrap" v-for="(items, i) in item.ansObj.ansTxt" :key="i">
                        <div v-if="i == item.ansObj.rightRes[1]">{{items}}<i class="rIcon">√</i></div>
                        <div v-else-if="i == item.ansObj.rightRes[1] && item.ansObj.status == 'right'">{{items}}<i class="rIcon">√</i></div>
                        <div v-else-if="i == item.ansObj.answer[1] && item.ansObj.status == 'wrong'">{{items}}<i class="wIcon">X</i></div>
                        <div v-else>{{items}}</div>
                    </div> -->
                    <div class="itemWrap" v-for="(items, i) in item.ansObj.ansTxt" :key="i">
                        <div>{{items}}
                            <span class="rIcon" v-if="i == item.ansObj.rightRes[1]">√</span>
                            <span class="wIcon" v-if="i == item.ansObj.answer[1] && item.ansObj.status == 'wrong'">x</span>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="btn">
                <a-button @click="back()" size="large" :style="{background: $store.state.themeColor}">返回</a-button>
            </div>
            </div>
        </div>
</template>

<script>
import {
    getScore
} from '../../http/api'
export default {
    data() {
        return {
            answerResult: {},
            answerList: [],
            show: false
        }
    },
    created() {
        
    },
    mounted() {
        
        getScore({
            result_id: this.$route.query.result_id
        }).then(res => {
            if(res.data.code == 200) {
                this.answerResult = res.data.data
                this.answerResult.examItems.map((item, i) => {
                    let answerArr
                    let qesAnsArr
                    if(typeof(item.answer) == "string") {
                        answerArr = item.answer.split('')
                        if(typeof(answerArr[1] == "string")) {
                            answerArr[1] = parseInt(answerArr[1])
                        }
                    }
                    if(typeof(item.question.answer) == "string") {
                        qesAnsArr = item.question.answer.split("")
                        if(typeof(qesAnsArr[1] == "string")) {
                            qesAnsArr[1] = parseInt(qesAnsArr[1])
                        }
                    }
                    let obj = {
                        name: item.question.stem,
                        ansObj: {
                            ansTxt: JSON.parse(item.question.metas).choices,
                            status: item.status,
                            answer: answerArr,
                            rightRes: qesAnsArr
                        }
                    }
                    this.answerList.push(obj)
                })
            }
            this.show = true
        }).catch(err => {
          
        })
        
    },
    methods: {
        back() {
            if(this.$route.query.course_type == 'classroom') {
                this.$router.push({
                    path: '/class/exam',
                    query: {
                        id: this.$route.query.courseId
                    }
                })
            } else {
                // this.$router.go(-2)
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                        id: this.$route.query.courseId,
                        course_type: this.$route.query.course_type,
                        index: 1
                    }
                })
            }
            
            
        }
    }

}
</script>

<style scoped>
.rIcon{
    background: #5cb85c;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    margin-left: 10px;
}
.wIcon {
    background: red;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    margin-left: 10px;
}
button {
    color: #fff!important;
}
.answerPaper {
    width: 1000px;
    margin: 0 auto;
    text-align: left;
}
.answerPaper .title {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.answerPaper .titleWrap {
    height: 120px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.answerPaper .itemTitle {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}
.answerPaper .score {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
}
.answerPaper .score span {
    font-size: 16px;
}
.answerPaper .answerList li {
    margin-bottom: 20px;
}
.answerPaper .answerList li .itemWrap div {
    padding: 5px 20px;
}
.answerPaper .btn {
    padding: 30px 0;
    text-align: center;
}
@media screen and (max-width: 1024px) {
    .answerPaper {
        width: 100%;
    }
}
</style>