<template>
    <div class="classes">
        <div class="classes-bg" :class="{'newbg':$store.state.tenant_idBind==3462}">
            <div class="inner">
                <div class="top">
                    <div class="title-wrap">
                        <img v-if="$store.state.tenant_idBind==3462" src="../../assets/images/class-title-icon2.png" alt="">
                        <img v-else src="../../assets/images/class-title-icon.png" alt="">
                        <h3>{{$store.state.hostname == 'lyedu.zjttv.cn' || $store.state.tenant_idBind == 1301 || $store.state.tenant_idBind == 1303? '培训项目': '课程中心'}}</h3>
                    </div>
                    <!-- <img style="width: 350px;" src="../../assets/images/bg_txt.png" alt=""> -->
                    <div class="search">
                        <a-input v-model="keywords" @keyup.enter="search" class="search-msg" placeholder="搜索感兴趣的内容"/>
                        <a-button @click="search" class="search-btn"></a-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bot">
            <div class="inner">
                <ul class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
                    <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :style="firstId == item.id?{borderColor: $store.state.themeColor,fontWeight: 'bold', color: $store.state.themeColor}:{}">{{item.name}}</li>
                </ul>
            </div>

            <div v-if="showMore" @click="showMoreMenu" class="menu-more">
                <!-- <span>{{moreMsg}}</span> -->
                <img v-if="moreMenuFlag" src="../../assets/images/more_up.png" alt="">
                <img v-if="!moreMenuFlag" src="../../assets/images/more_down.png" alt="">
            </div>
        </div>

        <div class="classes-content">
            <ul class="second-menus" v-if="firstId != 0 && second.length > 0">
                <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
            </ul>
            <ul class="third-menus" v-if="secondId != 0">
                <li @click="thirdScreening(item, i)" v-for="(item, i) in third" :key="i" :class="third_cate_id == item.id? 'ac': ''">{{item.name}}</li>
            </ul>
            <div class="loadding" v-show="!show">
            <a-spin />
            </div>
            <transition name="fades">
            <div v-if="show">
            <ul class="lesson-list" v-if="list.length > 0">
                <li :class="$store.state.template == 3? 'lesson-item-1x': 'lesson-item'" v-for="(item, i) in list" :key="i" @click="go(item, i)">
                    <img  class="img_a" :src="item.picture" alt="">
                    <div class="lesson-msg-wrap">
                        <p class="lesson-title">{{item.title}}</p>
                        <p class="lesson-about" v-if="item.type == 'link'">
                            课程来源：{{item.link_source}}
                        </p>
                        <div v-if="item.type != 'link'">
                            <p class="lesson-about">{{item.period}}课时 | {{item.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: item.learn_total}}人已学</p>
                            <p class="lesson-name" v-if="item.author && item.author.length > 0">
                                <span v-for="(itemss, iss) in item.author.slice(0, 3)" :key="iss">{{itemss.name}}<span v-if="item.author.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="item.author.length>1 && item.author.length<3">{{iss != 1?'、': ''}}</span></span>
                            </p>
                            <p class="lesson-name" v-else></p>
                            <div class="lesson-price-info">
                                <div v-if="priceShow()">
                                    <span class="lesson-price-sign" v-if="item.price != 0">￥</span>
                                    <span class="lesson-price">{{item.price > 0? item.price: '免费'}}</span>
                                </div>
                                <div>
                                    <van-rate size="14px" v-model="value" />
                                </div>
                            </div>
                        </div>
                    </div>

                </li>
            </ul>
            <div class="no" v-else>
                <img src="../../assets/images/no.png" alt="">
                <p>暂无数据</p>
            </div>
            </div>
            </transition>
            <a-pagination style="z-index:999;" v-if="list.length > 0" @change="changePage" v-model="page" :pageSize="page_size" :total="totalPage" />
        </div>


    </div>
</template>
<script>
import {
  first,
  second
} from '../../http/api'
import merge from 'webpack-merge'
export default {
    data() {
        return {
            value: 5,
            page: 1,
            page_size: 9,
            totalPage: 0,
            keywords: '',
            first: [],
            second: [],
            third: [],
            zero_cate_id: 0,
            firstId: 0,
            secondId: 0,
            third_cate_id: 0,
            list: [],
            show: false,
            showMore: false,
            moreMsg: '更多',
            moreMenuFlag: false
        }
    },
    mounted() {
        if(this.$store.state.template == 3) {
            this.page_size = 12
        }
        
        this.secondId = this.$route.query.second_cate_id? this.$route.query.second_cate_id: 0
        this.third_cate_id = this.$route.query.third_cate_id? this.$route.query.third_cate_id: 0
        first({
            zero_cate_id: this.zero_cate_id
        }).then(res => {
        if(res.data.code == 200) {
          this.first = res.data.data
          if(this.$store.state.lenovo_authorized) {
              this.firstId = this.$route.query.id? this.$route.query.id: res.data.data[0].id
          } else {
              this.firstId = this.$route.query.id? this.$route.query.id: 0
          }
          this.$nextTick(() => {
            let h = document.querySelector('.first-menus').scrollHeight
            if(h > 60) {
              this.showMore = true
            }
          })
          if(this.firstId) {
              first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId
            }).then(res => {
                if(res.data.code == 200) {
                    this.second = res.data.data
                }
            })
          }
          if(this.secondId) {
              first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId,
                second_cate_id: this.secondId
            }).then(res => {
                if(res.data.code == 200) {
                    this.third = res.data.data
                }
            })
          }
          this.update(this.page)
        }
      })
    },
    methods: {
        priceShow() {
            if(this.$store.state.tenant_idBind == 1222) {
                return false
            //} else if(this.$store.state.can_pay && this.$store.state.tenant_idBind != 22) {
            } else if(this.$store.state.can_pay) {
                return true
            }
        },
        showMoreMenu() {
            if(!this.moreMenuFlag) {
                this.moreMsg = '收起'
                this.moreMenuFlag = true
            } else {
                this.moreMsg = '更多'
                this.moreMenuFlag = false
            }
        },
        firstScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{id: item.id})
            })
            this.firstId = item.id
            this.page = 1
            this.secondId = 0
            this.keywords = ''
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                    this.second = res.data.data
                }
            })
            this.secondScreening({id: this.secondId})
        },
        secondScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{second_cate_id: item.id})
            })
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId,
                second_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                    this.third = res.data.data
                }
            })
            this.secondId = item.id
            this.third_cate_id = 0
            this.page = 1
            this.keywords = ''
            this.update(this.page)
        },
        thirdScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{third_cate_id: item.id})
            })
            this.third_cate_id = item.id
            this.page = 1
            this.keywords = ''
            this.update(this.page)
        },
        update(page) {
            this.show = false
            second({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId,
            second_cate_id: this.secondId,
            third_cate_id: this.third_cate_id,
            keywords: this.keywords,
            page: page,
            page_size: this.page_size,
            type: 1
            }).then(res => {
            this.show = true
            if(res.data.code == 200) {
                this.list = res.data.data.courses
                this.totalPage = res.data.data.ext.total
            }
            }).catch(err => {
            this.show = true
            this.$message.error(err.response.data.message)
            })
        },
        changePage(page, pageSize) {
            this.update(page)
        },
        search() {
            this.page = 1
            this.update(this.page)
        },
        go(item, i) {
            if(item.course_type == 'system_course') {
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                    id: item.id,
                    course_type: item.course_type
                    }
                })
            } else {
                if(item.type == 'link') {
                    window.open(item.link_address)
                } else {
                    this.$router.push({
                        path: '/course/studydetail',
                        query: {
                        id: item.id,
                        course_type: item.course_type
                        }
                    })
                }
            }
            
        },
    }
}
</script>
<style scoped>
.classes-bg {
    background: url('../../assets/images/class-bg.png') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
}
.title-wrap img {
    width: 54px;
    height: 54px;
    margin-right: 15px;
}
.title-wrap h3 {
    font-size: 30px;
    margin-bottom: 0;
    background: linear-gradient(to right, #DCFBFE, #6EA2DF);
    -webkit-background-clip: text;
    color: transparent;
}
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
}
.search-msg {
    width: 400px;
    height: 50px;
    border-radius: 0;
}
.search-btn {
    width: 50px;
    height: 50px;
    background: url('../../assets/images/search.png') no-repeat;
    border: none;
    border-radius: 0;
}
.classes-content {
    padding: 30px 0 40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}
.lesson-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.lesson-item {
    width: 386px;
    height: 356px;
    margin-right: 21px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px;
}
.lesson-item:hover, .classes-item:hover {
    box-shadow: 0 0 5px #ddd;
}
.lesson-item:nth-child(3n) {
    margin-right: 0;
}
.lesson-item img {
    height: 190px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-item-1x {
    width: 285px;
    height: 316px;
    margin-right: 20px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px;
}
.lesson-item-1x:hover, .classes-item:hover {
    box-shadow: 0 0 5px #ddd;
}
.lesson-item-1x:nth-child(4n) {
    margin-right: 0;
}
.lesson-item-1x img {
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-about {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}
.lesson-name {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lesson-msg-wrap {
    background: #FAFAFA;
}
.lesson-price-sign {
    color: #FF5E57;
}
.lesson-price {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.bot {
    box-shadow: 0 3px 5px #ccc;
}
.bot .first-menus {
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    padding-top: 18px;
}
.bot .first-menus li {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-right: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
}
.second-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.second-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.third-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    margin-bottom: 20px;
}
.third-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.loadding {
    flex: 1;
}
.ac-bg {
    color: #0880F1!important;
    background: rgba(194,224,252, .5)!important;
    border-radius: 4px;
    font-weight: bold;
}
.ac {
    color: #0880F1!important;
    font-weight: bold;
}
.no {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.no img {
    width: 100px;
    height: 100px;
}
.no p {
    color: #bbb;
}
.menu-more {
    color: #666;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-more img {
    width: 28px;
    height: 26px;
    padding: 5px;
    cursor: pointer;
}
</style>
