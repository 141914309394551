var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "top" }, [
        _c("h3", [_vm._v(_vm._s(_vm.title))]),
        _c("h6", [_vm._v(_vm._s(_vm.subTitle))]),
      ]),
      _vm.page == _vm.total_pages
        ? _c("div", { staticClass: "msg" }, [_vm._v(" 已经到顶部了! ")])
        : _vm._e(),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "padding-top": "30px" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function ($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading",
          },
        },
        [
          _c("div", { staticClass: "talkingRoom" }, [
            _c(
              "div",
              { staticClass: "txtWrap" },
              _vm._l(_vm.list, function (item, i) {
                return _c("div", { key: i }, [
                  _c(
                    "div",
                    { staticStyle: { color: "#666", "font-size": "12px" } },
                    [_vm._v(_vm._s(item.created_at))]
                  ),
                  _c("div", { staticClass: "talkingItem" }, [
                    _c("div", { staticClass: "pho" }, [
                      _c("img", { attrs: { src: item.avatar, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "talkingInfo" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.username)),
                      ]),
                      _c("p", { staticClass: "txt" }, [
                        _vm._v(_vm._s(item.content)),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _c(
        "a-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.btnShow,
              expression: "btnShow",
            },
          ],
          staticClass: "btn",
          on: { click: _vm.bot },
        },
        [_vm._v("您有新消息！点击查看")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }