var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe" }, [
    _c("div", { staticClass: "iframe_content" }, [
      _c("iframe", {
        staticStyle: {
          position: "absolute",
          top: "70px",
          left: "0",
          right: "0px",
          bottom: "100px",
          width: "100%",
          height: "90%",
        },
        attrs: {
          src: _vm.iframeSrc,
          id: "mobsf",
          scrolling: "no",
          allow: "microphone *;camera *;midi *;encrypted-media *;",
          frameborder: "0",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }