var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "question" },
    [
      _c(
        "h3",
        {
          staticClass: "title",
          style: { borderColor: _vm.$store.state.themeColor },
        },
        [_vm._v("我的提问")]
      ),
      _c(
        "ul",
        { staticClass: "list" },
        _vm._l(_vm.list, function (item) {
          return _c("li", [
            _c("h3", [_vm._v(_vm._s(item.content))]),
            _c("p", [_vm._v(_vm._s(item.latest_post_time))]),
            _c("span", { staticClass: "icon" }, [_vm._v("提问")]),
            _c("span", { staticClass: "icon2" }, [
              _vm._v(_vm._s(item.course.title)),
            ]),
          ])
        }),
        0
      ),
      _c("a-pagination", {
        attrs: {
          "default-current": _vm.page,
          total: _vm.total,
          pageSize: _vm.page_size,
        },
        on: { change: _vm.change },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }