<template>
  <div class="study">
    <div class="listWrap">
      <ListOne v-if="$store.state.template == 1"></ListOne>
      <ListTwo v-if="$store.state.template == 2"></ListTwo>
      <ListTwo v-if="$store.state.template == 3"></ListTwo>
      <tem-two-flex v-if="$store.state.template == 4"></tem-two-flex>
    </div>
   
  </div>
  
</template>

<script>
import ListOne from '@/components/study_list_template/one.vue'
import ListTwo from '@/components/study_list_template/tem-two-course.vue'
import TemTwoFlex from '../../components/study_list_template/tem-two-flex.vue'
import coo from '../../common/cookie.js'
export default {
    data() {
        return {

        }
    },
    components: {
      ListOne,
      ListTwo,
        TemTwoFlex
    },
    mounted() {
      scrollTo(0,0)
    },
    created(){
       coo.setLinkCookie(this.$router.history.current.fullPath)
    }
}
</script>

<style scoped>
.study .listWrap {
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .study .listWrap {
    width: 100%;
  }
}

</style>