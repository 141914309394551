var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "study" }, [
    _c(
      "div",
      { staticClass: "listWrap" },
      [
        _vm.$store.state.template == 1 ? _c("ListOne") : _vm._e(),
        _vm.$store.state.template == 2 ? _c("ListTwo") : _vm._e(),
        _vm.$store.state.template == 3 ? _c("ListTwo") : _vm._e(),
        _vm.$store.state.template == 4 ? _c("tem-two-flex") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }