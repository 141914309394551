<template>
    <div>
        <div class="top">
            <h3>{{title}}</h3>
            <h6>{{subTitle}}</h6>
        </div>
        <div class="msg" v-if="page == total_pages">
            已经到顶部了!
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-top: 30px;"><div class="talkingRoom">
                <div class="txtWrap">
                    <div v-for="(item, i) in list" :key="i">
                        <div style="color:#666;font-size: 12px">{{item.created_at}}</div>
                        <div class="talkingItem">
                            <div class="pho">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="talkingInfo">
                                <span class="name">{{item.username}}</span>
                                <p class="txt">{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-pull-refresh>
        <a-button @click="bot" v-show="btnShow" class="btn">您有新消息！点击查看</a-button>
    </div>
</template>

<script>
import {
    liveChat
} from '../../http/api'
import { Toast } from 'vant'
export default {
    data() {
        return {
            isLoading: false,
            title: '',
            subTitle: '',
            id: '',
            course_type: '',
            lesson_id: '',
            page: 1,
            page_size: 10,
            total_pages: 0,
            list: [],
            btnShow: false
        }
    },
    mounted() {
        this.id = window.location.search.split('&')[0].split('=')[1]
        this.course_type = window.location.search.split('&')[1].split('=')[1]
        this.lesson_id = window.location.search.split('&')[2].split('=')[1]
        this.update()

        let timer
        try {
            clearInterval(timer)
        } catch (error) {
            
        }
        timer = setInterval(() => {
            this.realTimeUpdate()
        }, 5000)
    },
    methods: {
        bot() {
            window.scrollTo(0 ,100000)
            this.btnShow = false
        },
        onRefresh() {
            if(this.page < this.total_pages) {
                this.page++
                this.update()
            } else {
                Toast(res.data.message)
            }
        },
        realTimeUpdate() {  //实时刷新
            liveChat({
                course_id: this.id,
                course_type: this.course_type,
                lesson_id: this.lesson_id,
                request_time: new Date().getTime()
            }).then(res => {
                if(res.data.code == 200) {
                    if(res.data.data.chats.length > 0) {
                        this.btnShow = true
                    }
                    res.data.data.chats.map((item, i) => {
                        this.list.push(item)
                    })
                }
            })
        },
        update() {  // 下拉刷新
            liveChat({
                course_id: this.id,
                course_type: this.course_type,
                lesson_id: this.lesson_id,
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                this.isLoading = false
                if(res.data.code == 200) {
                    this.total_pages = res.data.ext.total_pages
                    if(this.page == 1) {
                        this.title = res.data.data.course.title
                        this.subTitle = res.data.data.lesson.title
                        setTimeout(() => {
                            window.scrollTo(0 ,100000)
                        }, 100)
                    } else {
                        Toast('刷新成功')
                    }
                    res.data.data.chats.map((item, i) => {
                        this.list.unshift(item)
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.top {
    width: 100%;
    position: fixed;
    height: 80px;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.top h3, .top h6 {
    font-weight: bold;
}
.talkingRoom {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.talkingRoom .pho {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}
.talkingInfo .name {
    font-weight: bold;
    line-height: 14px;
    padding: 18px 10px;
    display: block;
}
.talkingInfo .txt {
    background: rgb(8, 128, 241);
    color: #fff;
    border-radius: 6px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 0;
}
.talkingItem {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.talkingInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.msg {
    padding: 45px 0 0;
    border-top: 1px solid #ddd;
    color: #888;
}
.btn {
    background: rgb(8, 128, 241);
    color: #fff;
    font-size: 12px;
    height: 30px;
    position: fixed;
    z-index: 9999999;
    right: 5px;
    bottom: 80px;
    border-radius: 15px;
    border: none;
}
</style>