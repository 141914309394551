<template>
    <div class="iframe">
       <div class="iframe_content">
        
        <iframe :src="iframeSrc" id="mobsf" scrolling="no" allow="microphone *;camera *;midi *;encrypted-media *;" frameborder="0" style="position:absolute;top:70px;
            left: 0;right:0px;bottom:100px;width:100%;height:90%;"></iframe>
        </div>
    </div>
</template>

<script>
import {checkLogin} from '../../http/api'
    let timer = null
    export default {
        data(){
           return{
                iframeSrc:''
           }
        },
        mounted(){
            this.iframeSrc = this.$route.query.src;
            let that = this;
            timer = setInterval(() => {
                that.checkLogin();
            }, 10000);
        },
        methods:{
            
            checkLogin(){
                let that = this;
                checkLogin({
                }).then(res => {
                    if(res.data.code == 200) {
                    
                    }
                }).catch(error=>{

                    clearInterval(timer);
                    timer = null;
                    that.$router.go(-1)

                })
            }
            
        }
    }
</script>

<style  scoped>
.iframe{
    width:100%;
    height: 100vh;
}
.iframe_content{
    min-height: 100vh;
}
</style>