<template>
  <div class="setpassword">
    <h3 class="title" :style="{borderColor: $store.state.themeColor}">基本信息</h3>
    <div class="content">
      <ul class="form">
        <li class="item">
          <label for=""><span class="red">*</span>旧密码</label>
          <a-input v-model="oldPassword" autocomplete="new-password" type="password" placeholder="请输入旧密码" />
        </li>
        <li class="item">
          <label for=""><span class="red">*</span>新密码</label>
          <a-input v-model="password" autocomplete="new-password" type="password" placeholder="请输入新密码" />
        </li>
        <li class="item">
          <label for=""><span class="red">*</span>确认密码</label>
          <a-input v-model="newPassword" autocomplete="new-password" type="password" placeholder="请确认新密码" />
        </li>
      </ul>
      <div class="do">
        <a-button @click="changePasswordSubmit()" :disabled="one && two && three?false:true" :style="{background:one && two && three?$store.state.themeColor:'#ccc'}">保存</a-button>
        <a-button @click="reset()" class="resetBtn">重置</a-button>
      </div>
    </div>

  </div>
</template>

<script>
import store from '../../store/index.js'
import {
  changePassword
} from '../../http/api'
export default {
    data() {
        return {
          oldPassword: '',
          password: '',
          newPassword: '',
          one: false,
          two: false,
          three: false
        }
    },
    mounted() {

    },
    watch: {
      'oldPassword'(n, o) {
        if(n) {
          this.one = true
        } else {
          this.one = false
        }
      },
      'password'(n, o) {
        if(n) {
          this.two = true
        } else {
          this.two = false
        }
      },
      'newPassword'(n, o) {
        if(n) {
          this.three = true
        } else {
          this.three = false
        }
      }
    },
    methods: {
      changePasswordSubmit() {
        if(this.oldPassword == "") {
          this.$message.error('请输入旧密码')
        } else if(this.password == "") {
          this.$message.error('请输入密码')
        } else if(this.newPassword == "") {
          this.$message.error('请输入新密码')
        } else if(this.password == this.newPassword) {
          changePassword({
            old_passwd: this.oldPassword,
            new_passwd: this.password,
            again_passwd: this.newPassword
          }).then(res => {
            if(res.data.code != 200) {
              this.$message.error(res.data.message)
            } else {
              this.$message.success(res.data.message)
              this.reset()
            }
          }).catch(err => {

        })
        } else {
          this.$message.error("新密码输入不一致！")
        }

      },
      reset() {
        this.oldPassword = ''
        this.password = ''
        this.newPassword = ''
      }
    }
}
</script>

<style scoped>
button {
  color: #fff!important;
  border: none;
}
.resetBtn {
  background: #fff;
  color: #333!important;
  border: 1px solid #bbb;
}
  .setpassword {
    padding: 20px;
    border: 1px solid #eee;
    min-height: 500px;
  }
  .setpassword .content {
    width: 320px;
    margin: 50px 0 0 50px;
  }
  .setpassword .title {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
  }
  .setpassword label {
    min-width: 80px;
    height: 40px;
    line-height: 40px;
  }
  .setpassword .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }
  .setpassword .item input {
    width: 240px;
    text-align: left;
    height: 40px;
  }
  .setpassword .do button:first-child {
    margin-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    .setpassword {
      min-height: 330px;
    }
    .setpassword .content {
      width: 100%;
      margin: 0;
    }
    .setpassword .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
</style>
