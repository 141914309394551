var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notice" }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _vm.$store.state.template == 1
          ? _c("List", { attrs: { type: null, cate: _vm.id } })
          : _vm._e(),
        _vm.$store.state.template == 2
          ? _c("ListTwo", {
              attrs: { type: null, cate: _vm.id, title: _vm.title },
            })
          : _vm._e(),
        _vm.$store.state.template == 3
          ? _c("ListThr", {
              attrs: { type: null, cate: _vm.id, title: _vm.title },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }